import React, { useContext } from "react";

const defaultState = {
  viewerOpen: false,
  viewerActiveIndex: null,
  viewerActiveAlbum: null,
  toggleDark: () => {},
  closeViewer: () => {},
  openViewer: () => {},
};

const ThemeContext = React.createContext(defaultState);
// Getting dark mode information from OS!
// You need macOS Mojave + Safari Technology Preview Release 68 to test this currently.
const supportsDarkMode = () =>
  window.matchMedia("(prefers-color-scheme: dark)").matches === true;
class ThemeProvider extends React.Component {
  state = {
    viewerOpen: false,
    viewerActiveAlbum: null,
    viewerActiveIndex: null,
  };
  toggleOpen = () => {
    const viewerOpen = !this.state.viewerOpen;

    this.setState({ viewerOpen });
  };

  closeViewer = () => {
    this.setState({
      viewerOpen: false,
      viewerActiveAlbum: null,
      viewerActiveIndex: null,
    });
  };

  openViewer = (album, index) => {
    this.setState({
      viewerOpen: true,
      viewerActiveAlbum: album,
      viewerActiveIndex: index,
    });
  };

  render() {
    const { children } = this.props;
    const { viewerOpen, viewerActiveAlbum, viewerActiveIndex } = this.state;
    return (
      <ThemeContext.Provider
        value={{
          viewerOpen,
          viewerActiveAlbum,
          viewerActiveIndex,
          toggleOpen: this.toggleOpen,
          closeViewer: this.closeViewer,
          openViewer: this.openViewer,
        }}
      >
        {children}
      </ThemeContext.Provider>
    );
  }
}
export default ThemeContext;

export { ThemeProvider };

export const useThemeState = () => {
  return useContext(ThemeContext);
};
